import WorkIm from '../assets/projects/workImg.jpeg'
import RealEst from '../assets/projects/realestate.jpg'
import Libor from '../assets/projects/libor.png'


export const data=[
    {
        id:1,
        name:"Libor Finance",
        image:Libor,
        github:"https://github.com/LiborProtocol",
        live:"https://libor.finance",
    },
    {
        id:2,
        name:"React JS Application",
        image:RealEst,
        github:"",
        live:"",
    },
    {
        id:3,
        name:"React JS Application",
        image:WorkIm,
        github:"",
        live:"",
    },
    {
        id:4,
        name:"React JS Application",
        image:RealEst,
        github:"",
        live:"",
    },


]